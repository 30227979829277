body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, "霞鹜文楷";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 霞鹜文楷;
    src: url("LXGWWenKai-Regular.ttf");
}

.ant-btn,
.ant-input-wrapper,
.ant-input,
#excel_file_input,
.ant-notification-notice-with-icon {
    font-family: 霞鹜文楷, monospace;
}

.ant-input,
.ant-input-number {
    background: rgba(255, 255, 255, 0.5) !important;
}

a {
    color: #1677ff;
    text-decoration: none;
}

.border_radius,
.ant-spin-blur::after {
    border-radius: 30px;
}